import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";

import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./App.scss";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Homepage from "./pages/Homepage";
import NotFound from "./pages/NotFound"; // Create a NotFound component for handling non-existent pages
import Resume from "./pages/Resume";
import Services from "./pages/Services";
import WorkDetails from "./pages/WorkDetails";
import WorkDetails2 from "./pages/WorkDetails2";
import Works from "./pages/Works";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" component={Homepage} exact />
        {/* <Route path="/multipage" component={Multipage} exact /> */}
        {/* <Route path="/blogs" component={Bloglist} exact />
        <Route path="/bloglist" component={Bloglist2} exact />
        <Route path="/blogs/:id/:title" component={BlogDetails} />
        <Route path="/blog-details/:id/:title" component={BlogDetails2} /> */}

        {/* For Multipage */}
        {/* <Route path="/multipage" component={Multipage} exact /> */}
        <Route path="/about" component={About} exact />
        <Route path="/services" component={Services} exact />
        <Route path="/resume" component={Resume} exact />
        <Route path="/works" component={Works} exact />
        <Route path="/works/:id/:title" component={WorkDetails} />
        <Route path="/work-details/:id/:title" component={WorkDetails2} />
        <Route path="/contact" component={Contact} exact />

        {/* Demo Purpose Only */}
        <Route path="/NotFound" component={NotFound} />
        <Redirect to="/NotFound" />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
